import * as React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useAddParticipantToRoom } from "../../../hooks/useApiCall";
import { useCookie } from "../../../hooks/useCookie";

interface Props {
  showDialog: boolean;
  onSubmitScore: (score: string) => void;
}

export const CustomCard = ({
  showDialog,
  onSubmitScore,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState<string>();
  const { addParticipant } = useAddParticipantToRoom();
  const { saveSession } = useCookie();

  useEffect(() => {
    if (showDialog) {
      onOpen();
    }
  }, [onOpen, showDialog]);

//   const addParticipantToRoom = useCallback(async () => {
//     if (name) {
//       const participantId = await addParticipant(roomId, name);
//       onParticipantCreated(participantId);
//       saveSession(participantId, roomId);
//     }
//   }, [addParticipant, name, saveSession, onParticipantCreated, roomId]);

  const onSubmit = useCallback(async () => {
    onSubmitScore(name ?? "")
    onClose();
  }, [name, onClose, onSubmitScore]);

  const cancelRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {}}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Enter your own score
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input
                placeholder="Ex. 1, 20 or 34"
                size="lg"
                type="number"
                onChange={(e) => setName(e.target.value)}
              />
            </AlertDialogBody>

            <AlertDialogFooter mt={4}>
              <Button
                colorScheme="blue"
                size="lg"
                onClick={onSubmit}
                disabled={!name}
                width={{ base: "100%" }}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
