import { HStack, Heading, theme } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Card } from "../../../components/Card";
import "./roomCardDeck.css";
import { CustomCard } from "../custom-card";

interface Props {
  onSelectedCard: (selectCard?: string) => void;
}

export const RoomCardDeck = ({ onSelectedCard }: Props) => {
  const [selectedCard, selectCard] = useState<string | null>(null);
  const [isShowCustomCardDialog, setShowCustomCardDialog] = useState<boolean>(false);
  const [decks] = useState<string[]>([
    "0.5",
    "1",
    "2",
    "3",
    "5",
    "8",
    "13",
    "20",
    "40",
    "?",
    "✎",
  ]);

  const onCustomScoreClicked = useCallback(() => {
    setShowCustomCardDialog(true)
  }, []);

  return (
    <>
      <HStack p={4}>
        {decks.map((card, index) =>
          card === selectedCard ? (
            <Card
              key={index}
              py={8}
              minW={16}
              textAlign="center"
              borderWidth="2px"
              borderColor={theme.colors.blue[500]}
              background={theme.colors.blue[100]}
              className="VotingCard"
              onClick={() => selectCard(null)}
            >
              <Heading fontSize="xl" color={theme.colors.blue[500]}>
                {card}
              </Heading>
            </Card>
          ) : (
            <Card
              key={index}
              py={8}
              minW={16}
              textAlign="center"
              className="VotingCard"
              onClick={() => {
                if (decks[index] === "✎") {
                  onCustomScoreClicked();
                } else {
                  onSelectedCard(card);
                }
                selectCard(card);
              }}
            >
              <Heading fontSize="xl">{card}</Heading>
            </Card>
          )
        )}
      </HStack>
      <CustomCard
        showDialog={isShowCustomCardDialog}
        onSubmitScore={(score) => {
          onSelectedCard(score);
        }}
      />
    </>
  );
};
